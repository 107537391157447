import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PostWrapper from "../../components/postWrapper"
import PostList from "../../components/postList"
import PostSidebar from "../../components/postSidebarFilter"
import Pagination from "../../components/pagination"

export const query = graphql`
  query ($slug: String, $skip: Int, $limit: Int) {
    allSanityBlogTech(filter: {category: {slug: {current: {eq: $slug}}}}, sort: {fields: publishDate, order: DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
          id
          title
          slug { current }
          category {
            title
            slug { current }
          }
          _rawThumbnail(resolveReferences: {maxDepth: 10})
          excerpt
        }
      }
    }
    sanityBlogSettings {
      heroTitleTech
      descriptionTech
    }
  }
`

const InsightTemplate = (props) => {
  const settings = props.data.sanityBlogSettings
  const blogs = props.data.allSanityBlogTech.edges
  const { currentPage, totalPages, slug } = props.pageContext

  return (
    <Layout theme="light">
      <SEO
        title={settings.heroTitleTech}
        description={settings.descriptionTech}
      />
      <Hero heroTitle={settings.heroTitleTech} theme="light" graph="radar" />
      <PostWrapper>
        <PostList items={blogs} type="blog/product" />
        <PostSidebar type="blogTech" />
      </PostWrapper>
      {totalPages > 1 && <Pagination base={`blog/product/${slug}/`} currentPage={currentPage} totalPages={totalPages} />}
    </Layout>
  )
}

export default InsightTemplate
